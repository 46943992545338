import React from "react";
import styled from "@emotion/styled";
import { H1 } from "../../styles/typography";
import MainLayout from "../layout/main-layout";

const WhereWeAreContainer = styled.div`
  background-color: #3a4648;
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 80px 0;
  }
`;

const LocationContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;

  @media screen and (min-width: 768px) {
    grid-template-columns: 50% 50%;
  }
`;

const Title = styled(H1)`
  font-size: 22px;
  letter-spacing: 0.64px;
  margin: 0;

  @media screen and (min-width: 768px) {
    font-size: 27px;
  }
`;

const Description = styled.p`
  font-size: 15px;
  letter-spacing: 0.45px;
  margin: 0;
  font-weight: 300;
  @media screen and (min-width: 768px) {
    font-size: 19px;
  }
`;

const Separator = styled.div`
  margin: 25px 0;
`;

const CustomLink = styled.a`
  color: var(--color3);
  text-decoration: none;
  font-size: 15px;
  letter-spacing: 0.45px;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    font-size: 19px;
  }
`;

const MapContainer = styled.div`
  height: 400px;
  box-sizing: border-box;
`;

const IframeMap = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const AddressLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const WhereWeAre = () => (
  <WhereWeAreContainer>
    <MainLayout>
      <LocationContainer>
        <div>
          <Title>Onde nos situamos?</Title>
          <AddressLink
            href="https://goo.gl/maps/uUyE1g1ZB42bARPX8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Description>
              Travessa de Silvares, nº 31
              <br />
              4505-198 Argoncilhe <br />
              Santa Maria da Feira
            </Description>
          </AddressLink>
          <Separator />

          <Title>Entre em contacto</Title>
          <Description>
            <CustomLink href="mailto:cogumelosdeargoncilhe@gmail.com">
              cogumelosdeargoncilhe@gmail.com
            </CustomLink>
          </Description>
          <Description>
            Linha direta:{" "}
            <CustomLink href="tel:939306102">939306102</CustomLink>
          </Description>
          <Separator />

          <Title>Horário de atendimento:</Title>
          <Description>Segunda a Sábado das 9:00 às 18:00</Description>
        </div>

        <MapContainer>
          <IframeMap
            id="gmap_canvas"
            title="Mapa"
            src="https://maps.google.com/maps?q=Cogumelos+de+Argoncilhe&t=k&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            loading="lazy"
          />
        </MapContainer>
      </LocationContainer>
    </MainLayout>
  </WhereWeAreContainer>
);

export default WhereWeAre;
