import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import WhereWeAre from "../components/contacts/where-we-are";
import ContactUs from "../components/contacts/contact-us";
import BackgroundImage from "../images/fundo-home.png";

const ContactosPage = () => (
  <Layout>
    <SEO
      title="Contactos"
      keywords={["contactos", "morada", "mapa", "horário"]}
      image={BackgroundImage}
    />

    <HeaderImage image={BackgroundImage}>
      <MainLayout size="60vh">
        <Header title="Prontos a ajudar…" subtitle="Contactos" />
      </MainLayout>
    </HeaderImage>
    <WhereWeAre />
    <ContactUs />
  </Layout>
);

export default ContactosPage;
