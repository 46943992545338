import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { H1 } from "../../styles/typography";
import MainLayout from "../layout/main-layout";

const ContactUsContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  color: #3a4648;

  @media screen and (min-width: 768px) {
    padding: 0 80px 80px 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 0 0 80px 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SharedInputStyled = css`
  font-size: 15px;
  background-color: #ffffff;
  border: none;
  height: 50px;
  padding: 10px;
  width: 100%;
  color: #3a4648;
  box-sizing: border-box;
  border: 1px solid #3a4648;
  outline: none;
  border-radius: 5px;
  ::placeholder {
    color: #3a4648;
  }
`;

const StyledInput = styled.input`
  ${SharedInputStyled}
  height: 50px;
`;

const StyledTextarea = styled.textarea`
  ${SharedInputStyled}
  height: 125px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  width: 100%;
  font-size: 21px;
`;

const StyledButton = styled.button`
  border-radius: 6px;
  background-color: #dd614a;
  color: #fff;
  border: 0;
  padding: 15px 0;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
  text-transform: upperCase;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background-color: #b80c09;
  }

  @media screen and (min-width: 768px) {
    width: 150px;
  }
`;

const ButtonContainer = styled.p`
  display: flex;
  justify-content: flex-end;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #3a4648;
  margin-bottom: 7px;
`;

const Paragraph = styled.p`
  margin: 10px 0;
`;

const Firstname = styled(Paragraph)`
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-right: 10px;
    width: 50%;
  }
`;

const Lastname = styled(Paragraph)`
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

const Title = styled(H1)`
  color: #3a4648;
`;

const ContactUs = () => (
  <ContactUsContainer>
    <MainLayout>
      <TitleContainer>
        <Title>Formulário de contacto</Title>
      </TitleContainer>

      <FormContainer>
        <Form
          name="contacto"
          action="/?action=contacto"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contacto" />
          <input type="hidden" name="subject" value="Contacto" />
          <Paragraph>
            <Label htmlFor="assunto">Assunto</Label>
            <StyledInput type="text" name="assunto" required id="assunto" />
          </Paragraph>
          <Paragraph>
            <Label htmlFor="email">E-mail</Label>
            <StyledInput type="email" name="email" id="email" required />
          </Paragraph>
          <NameContainer>
            <Firstname>
              <Label htmlFor="primeiro-nome">Primeiro Nome</Label>
              <StyledInput
                type="text"
                name="primeiro-nome"
                id="primeiro-nome"
                required
              />
            </Firstname>
            <Lastname>
              <Label htmlFor="ultimo-nome">Último Nome</Label>
              <StyledInput
                type="text"
                name="ultimo-nome"
                id="ultimo-nome"
                required
              />
            </Lastname>
          </NameContainer>

          <Paragraph>
            <Label htmlFor="mensagem">Mensagem</Label>
            <StyledTextarea name="mensagem" id="mensagem" required />
          </Paragraph>
          <ButtonContainer>
            <StyledButton type="submit">Enviar</StyledButton>
          </ButtonContainer>
        </Form>
      </FormContainer>
    </MainLayout>
  </ContactUsContainer>
);

export default ContactUs;
